.mainContainer {
  padding: 5px 15px 10px;
}

.transcript {
  text-align: center;
  resize: none;
  background-color: white;
  border-radius: 20px;
}
