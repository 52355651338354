.mainContainer {
  width: 100%;
  height: 200px;
  text-align: center;
}
.merge {
  font-size: larger;
  padding-top: 20px;
}

.isdteContainer {
  padding-top: 20px;
}

.isd {
  width: 25%;
  margin: 0px;
  font-size: small;
  display: inline-block;
  background-color: #dbd3d3;
  border-radius: 15px;
}

.isdContainer {
  padding: 3px 2px;
}

.time {
  width: 40%;
  display: inline-block;
  vertical-align: top;
  background-color: #dbd3d3;
  border-radius: 15px;
  margin: 0px 10px;
  justify-content: space-between;
}

.timeContainer {
  height: 33px;
  margin: 8px 5px;
}

.marginBottom {
  margin-bottom: 13px;
}

.export {
  width: 24%;
  display: inline-block;
}
