.mainContainer {
  height: 100%;
  width: 100%;
  min-width: 1000px;
  padding: 0;
  overflow-x: hidden;
}

.mainRow {
  height: 100%;
  width: 100%;
  padding: 0px 0px 0px 12px;
}

.mainColumn {
  padding: 0;
}

.menuColumn {
  position: relative;
  width: 100%;
  min-width: 210px;
  height: 100%;
  padding: 0;
  color: black;
  background-color: orange;
}

.logoContainer {
  cursor: pointer;
  text-align: center;
  padding-top: 20px;
}

.logo {
  height: 80px;
}

.logo:hover {
  scale: 1.2;
}

.menuList {
  margin-left: -2px;
  font-size: 1.4rem;
  padding: 30% 0%;
  list-style-type: none;
}

.menuItem {
  cursor: pointer;
  width: 100%;
  text-align: left;
  background-color: orange;
  border: none;
}

.menuItem:hover {
  background-color: rgb(255, 208, 0);
}

.itemText {
  height: 100%;
  padding: 0% 7%;
}

.activeItem {
  margin-left: 2px;
  color: white;
  background-color: black;
}

.activeItem:hover {
  background-color: black;
}

.iconsColor {
  color: white;
}

.logoutContainer {
  position: absolute;
  width: 100%;
  bottom: 95px;
  left: 0;
  font-size: 1.2rem;
  padding: 0% 5%;
  color: white;
}

.logoutButton {
  color: white;
}

.logoutButton:hover {
  background-color: red;
}

.contentArea {
  height: 100%;
  padding: 0% 0% 0% 8%;
}

.titleText {
  font-size: 1.4rem;
  padding-top: 30px;
  margin: 0;
}

.hrLine {
  width: 200px;
  margin-left: 10px;
}

.projectRow {
  position: relative;
  height: 100px;
  padding: 0;
  margin: 0;
}

.projectContainer {
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 0;
}

.projectWrapper {
  position: relative;
  width: 190px;
  height: 100%;
  padding: 0px;
  border-radius: 20px;
  display: inline-block;
}

.middle {
  transition: 0.3s ease;
  width: 95%;
  height: 99%;
  border-radius: 10px;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  background-color: rgba(0, 0, 0, 0.2);
  display: grid;
  place-items: center;
}

.middle:hover {
  cursor: pointer;
}

.projectWrapper:hover .videoThumb {
  opacity: 0.5;
}

.projectWrapper:hover .middle {
  opacity: 1;
}

.editIcon {
  scale: 2;
}

.newProject {
  width: 190px;
  min-width: 190px;
  height: 100%;
  border-radius: 10px;
  padding: 0px;
  background-color: #dbd3d3;
  display: inline-block;
  cursor: pointer;
}

.newProject:hover .textWrapper {
  scale: 1.1;
}

.iconContainer {
  position: relative;
  display: flex;
  height: 100%;
  justify-content: center;
  text-align: center;
  align-items: center;
  border-radius: 20px;
  vertical-align: middle;
}

.partsWrapper {
  display: grid;
  place-items: center;
  width: 100%;
}

.iconWrapper {
  display: block;
  width: 27px;
  background-color: #706f6f;
  border-radius: 50px;
  line-height: 15px;
}

.newProjectIcon {
  scale: 1.2;
  color: white;
  padding: 6px;
}

.textWrapper {
  height: 30px;
  display: block;
}

.videoThumb {
  width: 190px;
  height: 100px;
  object-fit: contain;
  border-radius: 20px;
  cursor: pointer;
  opacity: 1;
  display: block;
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.middleDiv {
  height: 60px;
  width: 760px;
  padding: 0;
  margin-left: 10px;
  background-image: linear-gradient(
    to right,
    red,
    orange,
    yellow,
    green,
    blue,
    indigo,
    violet
  );
  border-radius: 20px;
}

.videoRow {
  width: 780px;
}

.bottomDiv {
  height: 120px;
  width: 100%;
  margin: 0;
  background-image: linear-gradient(
    to right,
    rgba(255, 0, 0, 0),
    rgba(255, 0, 0, 1)
  );
  border-radius: 20px;
}

.popupBodyContainer {
  width: 100%;
  height: 100%;
  padding-top: 20px;
  display: grid;
  place-items: center;
}

.videoDropContainer {
  display: grid;
  place-items: center;
  width: 400px;
  height: 100px;
  border: dashed;
  border-radius: 20px;
}

.inputLabel {
  max-width: 380px;
  word-break: break-all;
  white-space: initial;
  cursor: pointer;
  padding: 5px;
  border: solid;
  border-width: 0.1rem;
  border-radius: 10px;
}

.inputFile {
  display: none;
}

.videoFooterContainer {
  width: 100%;
  padding: 10px 60px;
  display: flex;
  justify-content: space-between;
  font-size: small;
}

.selectLabel {
  padding: 0px 5px;
}

.SelectionPop {
  width: 100px;
  text-align: center;
  padding-left: 10px;
  border: none;
  background-color: orange;
  border-radius: 10px;
}

.footerContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.popupButton {
  width: 100px;
  height: 25px;
  border: none;
  border-radius: 15px;
  font-size: small;
}

.popupSatrt {
  background-color: greenyellow;
}

.popupSatrt:hover {
  color: white;
  background-color: rgb(3, 105, 3);
}

.popupCancel {
  color: white;
  background-color: #706f6f;
}

.popupCancel:hover {
  background-color: red;
}
