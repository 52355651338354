.statusContainer {
  width: 100%;
  display: grid;
  place-items: center;
}

.status {
  padding: 0;
}

.messageContainer {
  text-align: center;
  margin: 0;
}

.message {
  color: rgb(179, 118, 6);
}
