.mainContainer {
  position: relative;
  padding: 0px 15px;
  scale: 1.1;
}

.rowContainer {
  padding: 5px;
}

.buttonContainer {
  display: flex;
  width: 100%;
  justify-content: center;
  border-radius: 60px;
  border: none;
}

.button {
  position: absolute;
  top: 23%;
  background-color: orange;
  padding: 12px;
  border: none;
  border-radius: 30px;
}

.buttonIcon {
  font-size: 1.6rem;
}

.textContainer {
  display: inline-block;
  width: 50%;
  padding: 0px 5px;
}

.transcript {
  border-radius: 15px;
  resize: none;
}

.transcript::-webkit-scrollbar {
  display: none;
}

.farsi {
  direction: rtl;
  font-family: "Noto Sans Arabic", sans-serif;
}
