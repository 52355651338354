.mainContainer {
  width: 100%;
  height: 100%;
  padding: 0;
  color: black;
  background-color: white;
}

.logoContainer {
  cursor: pointer;
  text-align: center;
  padding-top: 20px;
}

.logo {
  height: 80px;
}

.logo:hover {
  scale: 1.2;
}

.menuList {
  margin-left: -2px;
  font-size: 1.4rem;
  padding: 30% 0%;
  list-style-type: none;
}

.menuItem {
  cursor: pointer;
  width: 100%;
  text-align: left;
  background-color: white;
  border: none;
}

.menuItem:hover {
  background-color: rgb(255, 208, 0);
}

.itemText {
  height: 100%;
  padding: 0% 7%;
}

.activeItem {
  margin-left: 2px;
  color: white;
  background-color: black;
}

.activeItem:hover {
  background-color: black;
  color: white;
}

.logoutContainer {
  position: absolute;
  bottom: 55px;
  left: 0;
  font-size: 1.2rem;
  padding: 0% 5%;
  color: white;
}

.logoutButton {
  color: black;
}

.logoutButton:hover {
  background-color: red;
}
