/* English Font */
@import url("https://fonts.googleapis.com/css2?family=Lilita+One&display=swap");
/* Farsi Font */
@import url("https://fonts.googleapis.com/css2?family=Lilita+One&family=Noto+Sans+Arabic:wght@100..900&display=swap");

/* App level section */
body,
html {
  height: 100%;
  font-family: "Lilita One", sans-serif;
  overflow-x: hidden;
}

#root {
  height: 100%;
}
