.mainContainer {
  width: 100%;
  height: 100%;
  min-width: 900px;
  background-color: #ede8dc;
  background-image: linear-gradient(#dfdcd7, rgb(238, 231, 231));
  padding: 0;
  overflow: hidden;
}

.headerSection {
  width: 100%;
  height: 70px;
  font-size: 2rem;
  padding: 7px 0px 0px 40px;
}

.logoContainer {
  height: 50px;
}

.logo {
  height: 100%;
}

.iconColumn {
  text-align: center;
}

.headerIcon {
  width: fit-content;
  height: 100%;
  float: right;
  padding: 0px 2px 2px;
  vertical-align: middle;
  background-color: white;
  border-radius: 28px;
  border-color: black;
}

.headerIcon:hover {
  scale: 1.2;
}

.userIcon {
  background-color: rgb(40, 189, 226);
  vertical-align: middle;
  padding: 9px 10px;
  border-radius: 28px;
  color: #ede8dc;
}

.editAreaContainer {
  display: inline-block;
  width: 58%;
  margin: 0;
  padding: 0px 15px 0px 35px;
}

.editPanelContainer {
  display: inline-block;
  width: 100%;
  padding-top: 20px;
}

.mergeButtonContainer {
  position: absolute;
  top: 250px;
  left: -27px;
  width: fit-content;
  transform: rotate(90deg);
}

.mergeButtonCol {
  padding: 0;
}

.buttonContainer {
  transform: rotate(180deg);
  padding: 0;
}

.displayVideoContainer {
  position: relative;
  display: inline-block;
  text-align: center;
  vertical-align: top;
  width: 40%;
  min-height: 400px;
  margin: 0;
  padding: 0%;
  border-radius: 15px;
}

.upload {
  padding: 18% 5%;
}

.inputContainer {
  vertical-align: middle;
}

.inputLabel {
  display: inline-block;
  height: 100%;
  cursor: pointer;
  background-color: white;
  color: black;
  width: 100%;
  padding: 10px;
  text-align: left;
  vertical-align: middle;
  border-radius: 15px;
}

.inputFile {
  display: none;
}

.uploadButton {
  border: none;
  background-color: black;
  color: orange;
  border-radius: 10px;
  height: 35px;
  width: 100%;
  margin-top: 2px;
}

.uploadButton:hover {
  background-color: rgb(88, 85, 85);
  color: rgb(252, 207, 123);
}

.exportContainer {
  margin: 0;
  padding-right: 15px;
  width: 100%;
}

.refreshButtonContainer {
  display: flex;
  width: 100%;
  padding: 10px 15px 3px 0px;
  justify-content: end;
  align-items: end;
}

.refresh {
  border: none;
  border-radius: 10px;
  background-color: blueviolet;
  color: white;
  padding: 3px 17px;
}

.refresh:hover {
  background-color: white;
  color: black;
}

.editPanel {
  padding: 15px 0px;
}

.timelineRow {
  height: 100px;
  padding: 0% 2%;
}

.displayNone {
  display: none;
}
