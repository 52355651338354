.mainContainer {
  padding: 0px 40px 20px;
}

.editContainers {
  background-color: #dbd3d3;
  border-radius: 10px;
  border-color: #dbd3d3;
}

.buttonContainer {
  padding: 2px 2px;
  border-color: #dbd3d3;
}

.editButton {
  background-color: orange;
  color: white;
  padding: 1px 0px;
  border-radius: 10px;
  width: 100%;
  border: none;
}

.editButton:hover {
  color: black;
  background-color: white;
}

.blackBackground {
  background-color: black;
}

.inputColumn {
  padding: 0;
  height: 100%;
}

.inputContainer {
  height: 30px;
}

.inputRow {
  height: 100%;
}

.inputWrapper {
  width: 100%;
  height: 30px;
  overflow: hidden;
  padding: 0px 2px;
}

.inputButtonContainer {
  display: inline-block;
  height: 101%;
  width: 13%;
}

.inputButton {
  width: 100%;
  height: 100%;
  padding: 0;
  border: none;
  background-color: orange;
  color: white;
  border-radius: 0px 10px 10px 0px;
}

.inputButton:hover {
  background-color: white;
  color: black;
}

.inputButtonLeft {
  border-radius: 10px 0px 0px 10px;
}

.timeInputContainer {
  display: inline-block;
  width: 74%;
  height: 100%;
}

.timeInput {
  width: 100%;
  height: 100%;
  padding: 0px;
  font-size: medium;
  background-color: orange;
  text-align: center;
  color: white;
  border: none;
}

.blackTimeInput {
  background-color: black;
}

.timeInput::placeholder {
  color: white;
}

.timeInput:focus {
  color: black;
  background-color: white;
}

.timeInput:focus::placeholder {
  color: black;
}
