.mainContainer {
  border-radius: 10px;
  margin: 0;
  text-align: center;
  height: 90px;
  background-color: #dbd3d3;
}

.timelineContainer {
  width: 100%;
  height: 90%;
}

.input {
  width: 100%;
  height: 45%;
  padding: 0% 0%;
}

.textTime {
  width: 150px;
  background-color: #a7b49e;
  border: none;
  border-radius: 5px;
  text-align: center;
}

.backForButton {
  display: inline-block;
  color: white;
  background-color: black;
  border: none;
  padding: 5px 8px;
  border-radius: 15px;
  margin: 0px 5px;
}

.playButton {
  display: inline-block;
  border: none;
  color: white;
  background-color: black;
  padding: 10px 13px;
  border-radius: 30px;
  margin: 0px 5px;
}

.icons {
  scale: 1.5;
}
