.mainContainer {
  position: relative;
}

.mergeContainer {
  position: absolute;
  top: 165px;
  left: -75px;
  transform: rotate(90deg);
  z-index: 5;
}

.loadingContainer {
  padding: 20% 28%;
}
