.mainContainer {
  padding: 130px 0%;
  height: 350px;
  border-radius: 15px;
  background-color: #dbd3d3;
  overflow: auto;
}

.mainContainer::-webkit-scrollbar {
  display: none;
}

.ul {
  list-style-type: none;
  padding: 0;
}

.singleRow {
  display: flex;
}

.liRow {
  height: 100px;
}
