.mainContainer {
  display: inline-block;
}

.button {
  width: 100%;
  text-align: center;
  border: none;
  padding: 2px 15px 2px 5px;
  color: white;
  background-color: black;
  border-radius: 15px 0px 0px 15px;
}

.button:hover {
  color: black;
  background-color: white;
}
