.button {
  width: 50%;
  height: 100%;
  text-align: center;
  border: none;
  color: white;
  background-color: orange;
  border-radius: 0px 10px 10px 0px;
  display: inline-block;
}

.mergeIcon {
  transform: rotate(90deg);
}
