.mainContainer {
  background-image: linear-gradient(#dfdcd7, rgb(238, 231, 231));
  height: 100%;
  width: 100%;
  overflow: scroll;
}

.noScroll {
  overflow: hidden;
}

.sidePanelContainer {
  position: absolute;
  left: -70%;
  top: 0%;
  width: 60%;
  height: 100%;
  z-index: 10;
  color: black;
  transition: left 1s ease;
}

.innerContainer {
  width: 100%;
  height: 100%;
}

.subtitleAreaContainer {
  padding-top: 20px;
}

.openMenu {
  left: 0%;
}

.timelineContainer {
  height: 70px;
  margin: 30px 5px;
}
