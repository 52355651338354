@import url("https://fonts.googleapis.com/css2?family=Lilita+One&display=swap");

.mainContainer {
  width: 100px;
  height: 100%;
  background-color: #dbd3d3;
  border-radius: 10px;
}

.rowContain {
  width: 100%;
  padding: 0;
  margin: 0;
}

.export {
  font-family: "Lilita One", sans-serif;
  font-size: small;
  color: black;
  border: none;
  background-color: #dbd3d3;
  border-radius: 10px;
}

.header {
  height: 30px;
  padding-top: 5px;
}

.hrContainer {
  padding: 0px 17px;
}

.hr {
  margin: 0px;
}

.mp4Button {
  width: 95%;
  border-radius: 20px;
  color: black;
  padding: 5px 0px;
  margin-top: 2px;
}

.mp4Button:hover {
  color: aliceblue;
}

.greenBackground {
  background-color: rgb(29, 197, 29);
}

.srtButton {
  border-radius: 20px;
  width: 95%;
  color: black;
  padding: 5px 0px;
  margin-bottom: 2px;
}

.srtButton:hover {
  color: aliceblue;
}
