@import url("https://fonts.googleapis.com/css2?family=Lilita+One&display=swap");

.mainContainer {
  font-family: "Lilita One", sans-serif;
  display: inline-block;
}

.button {
  width: 100%;
  text-align: center;
  border: none;
  padding: 2px 5px 2px 15px;
  color: white;
  background-color: orange;
  border-radius: 0px 15px 15px 0px;
}

.mergeIcon {
  transform: rotate(180deg);
}

.button:hover {
  color: black;
  background-color: white;
}
