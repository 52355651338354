.mainContainer {
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
  margin: 10px -35px;
  scrollbar-width: none;
}

.timeColumn {
  display: inline-block;
  width: 150px;
  padding: 0px;
  padding-left: 30px;
  margin-right: 5%;
}

.TimeContainer {
  font-size: medium;
  scale: 0.7;
  padding: 1px;
}

.transcriptColumn {
  display: inline-block;
  width: 55%;
  margin-left: 5%;
}

.textarea {
  width: 100%;
  padding: 5px;
  resize: none;
  border: none;
  border-radius: 10px;
  background-color: rgb(238, 231, 231);
}
