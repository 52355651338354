.mainContainer {
  width: 100%;
  min-width: 350px;
  height: 100%;
  overflow: hidden;
}

.headerContainer {
  padding-top: 20px;
  padding-left: 30px;
  width: 100%;
  height: 50px;
}

.logoImage {
  height: 40px;
}

.bodyContainer {
  display: flex;
  height: 75%;
  text-align: center;
  object-fit: cover;
  justify-content: center;
  align-items: center;
}

.bodyImage {
  width: 90%;
  padding: 0;
  margin: 0;
}

.footerContainer {
  text-align: center;
  min-width: 400px;
}

.homepageButton {
  font-size: large;
  border: none;
  background-color: orange;
  border-radius: 20px;
  padding: 10px 20px;
}

.homepageButton:hover {
  background-color: rgb(181, 250, 53);
}
