@import url("https://fonts.googleapis.com/css2?family=Lilita+One&display=swap");

.mainContainer {
  width: 100%;
  height: 30px;
  padding: 0px 2px;
}

.inputButton {
  display: inline-block;
  vertical-align: middle;
  background-color: orange;
  color: white;
  border: none;
  width: 13%;
  height: 100%;
  padding: 0;
  border-radius: 0px 10px 10px 0px;
}

.inputButton:hover {
  background-color: white;
  color: black;
}

.inputButtonLeft {
  border-radius: 10px 0px 0px 10px;
}

.timeInput {
  display: inline-block;
  vertical-align: middle;
  width: 74%;
  height: 100%;
  padding: 0px;
  font-size: medium;
  background-color: orange;
  text-align: center;
  color: white;
  border: none;
}

.blackBg {
  background-color: black;
}

.timeInput::placeholder {
  color: white;
}

.timeInput:focus {
  color: black;
  background-color: white;
}

.timeInput:focus::placeholder {
  color: black;
}
