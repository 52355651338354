.mainContainer {
  position: relative;
  padding: 0px 25px;
}

.mergeButtonContainer {
  position: absolute;
  top: 45px;
  left: -70px;
  width: fit-content;
  transform: rotate(90deg);
}

.mergeButtonCol {
  padding: 0;
}

.buttonContainerM {
  transform: rotate(180deg);
  padding: 0;
}

.rowContainer {
  padding: 0% 7% 0% 0%;
}

.buttonContainer {
  display: flex;
  width: 100%;
  justify-content: center;
  border-radius: 60px;
  border: none;
}

.button {
  position: absolute;
  top: 28%;
  background-color: orange;
  padding: 12px;
  border: none;
  border-radius: 30px;
}

.buttonIcon {
  font-size: 1.6rem;
}

.transcript {
  resize: none;
}

.transcript::-webkit-scrollbar {
  display: none;
}

.farsiDirection {
  direction: rtl;
  font-family: "Noto Sans Arabic", sans-serif;
}
