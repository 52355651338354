@import url("https://fonts.googleapis.com/css2?family=Lilita+One&display=swap");

.mainContainer {
  padding: 2px 2px;
  border-color: #dbd3d3;
  font-family: "Lilita One", sans-serif;
}

.button {
  background-color: orange;
  color: white;
  text-align: left;
  padding: 1px 15px;
  border-radius: 10px;
  width: 100%;
  border: none;
}

.button:hover {
  color: black;
  background-color: white;
}
