.mainContainer {
  width: 100%;
  height: 250px;
}

.uploadContainer {
  padding: 60px 10px;
}

.video {
  width: 100%;
  height: 250px;
  object-fit: contain;
  padding: 10px;
}
