.mainContainer {
  background-image: url("../../../data/images/auth-background.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100%;
  min-height: 600px;
}

.innerContainer {
  overflow: hidden;
}

.cardContainer {
  margin-top: 20px;
  height: 100%;
  text-align: center;
  align-content: center;
  align-items: center;
  vertical-align: middle;
  background-color: transparent;
  background-color: rgba(255, 255, 255, 0.5);
  overflow: hidden;
}

.cardBody {
  padding: 1% 0%;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.logoContainer {
  margin: auto;
  text-align: center;
}

.logo {
  height: 50px;
}

.form {
  padding: 1% 5%;
}

.formControl {
  border-radius: 20px;
  padding-left: 4%;
  background-color: #10375c;
  color: white;
}

.inputPlaceholder::placeholder {
  color: white;
}

.serviceTermRow {
  width: 100%;
  padding: 1% 0% 2% 4%;
  text-align: center;
  font-size: small;
}

.serviceTermContainer {
  text-align: center;
}

.checkboxDirection {
  direction: rtl;
}

.RobotContainer {
  font-size: large;
  font-weight: 300;
  text-align: center;
  padding: 0% 0% 3%;
}

.robotCheckbox {
  transform: scale(2);
}

.robotLabel {
  padding: 0% 3%;
}

.buttonContainer {
  padding: 0% 7%;
}

.button {
  width: 100%;
  border-radius: 20px;
  color: white;
}

.button:hover {
  color: white;
}

.buttonColor {
  background-color: #10375c;
  border-color: #10375c;
}

.alternative {
  width: 100%;
  padding: 0%;
  text-align: center;
}

.question {
  height: 25px;
}

.loginLink {
  text-align: right;
  text-decoration: none;
  color: white;
}

.alert-messages-container {
  align-items: center;
}

.homepageLink {
  width: 100%;
  padding: 2%;
  text-align: center;
}

/* Alerts */
.dismissButtonContainer {
  padding: 0%;
}

.dismissButton {
  font-size: 1rem;
  color: none;
  background-color: #bcf2f6;
  border: none;
}

.alertDisplayInline {
  border-radius: 10px;
  padding: 1%;
  background-color: #bcf2f6;
  border-color: aquamarine;
  display: block;
}

.alertDisplayNone {
  display: none;
}
