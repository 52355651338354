.mainContainer {
  width: 100%;
  height: 100%;
  padding: 3px 10px;
}

.loadingStatusContainer {
  padding: 0px;
}

.readyTextContainer {
  padding-top: 15px;
}

.readyText {
  font-size: medium;
}

.displayNone {
  display: none;
}

.loadingStatus {
  display: inline-block;
}

.loadingText {
  display: inline-block;
  padding: 0px 10px;
}
