.mainContainer {
  width: 100%;
  height: 100%;
}

.input {
  width: 100%;
  height: 100%;
  padding: 3px 0px;
  margin: 0;
  border: none;
  border-radius: 10px;
  text-align: center;
  color: white;
  background-color: orange;
}

.blackBg {
  background-color: black;
}
