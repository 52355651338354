.mainContainer {
  width: 100px;
}

.modal {
  width: 100%;
  padding: 0px 25px;
}

.popupBodyContainer {
  width: 100%;
  height: 100%;
  padding-top: 20px;
  display: grid;
  place-items: center;
}

.videoDropContainer {
  display: grid;
  place-items: center;
  width: 100%;
  height: 180px;
  border: dashed;
  border-radius: 20px;
}

.inputFileContainer {
  padding: 5px 20px;
  max-width: 320px;
  word-break: break-all;
  white-space: initial;
}

.inputLabel {
  cursor: pointer;
  padding: 10px;
  border: solid;
  border-width: 0.1rem;
  border-radius: 10px;
}

.inputFile {
  display: none;
}

.videoFooterContainer {
  width: 100%;
  padding: 10px 5px;
  font-size: medium;
}

.languageContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.labelContainer {
  display: inline-block;
}

.selectContainer {
  display: inline-block;
}

.selectLabel {
  padding: 0px 5px;
}

.SelectionPop {
  width: 100px;
  text-align: center;
  padding-left: 10px;
  border: none;
  background-color: orange;
  border-radius: 10px;
}

.footerContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.popupButton {
  width: 100px;
  height: 25px;
  border: none;
  border-radius: 15px;
  font-size: small;
}

.popupSatrt {
  background-color: greenyellow;
}

.popupSatrt:hover {
  color: white;
  background-color: rgb(3, 105, 3);
}

.popupCancel {
  color: white;
  background-color: #706f6f;
}

.popupCancel:hover {
  background-color: red;
}
