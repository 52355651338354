.mainContainer {
  width: 100%;
}

.sidePanelContainer {
  position: absolute;
  left: -70%;
  top: 0%;
  width: 60%;
  height: 100%;
  z-index: 10;
  color: black;
  transition: left 1s ease;
}

.openMenu {
  left: 0;
}

.innerContainer {
  width: 100%;
  justify-content: center;
}

.projects {
  width: 100%;
  color: black;
  text-align: center;
}

.titleText {
  font-size: 1.4rem;
  padding-top: 10px;
  margin: 0;
}

.hrLine {
  width: 200px;
  padding-bottom: 10px;
  margin: auto;
}

.newProject {
  display: inline-block;
  vertical-align: top;
  background-color: #dbd3d3;
  border-radius: 10px;
  padding: 0px;
  cursor: pointer;
}

.iconContainer {
  display: flex;
  width: 185px;
  height: 100px;
  justify-content: center;
  text-align: center;
  align-items: center;
  border-radius: 20px;
}

.partsWrapper {
  display: grid;
  place-items: center;
  width: 100%;
}

.iconWrapper {
  display: block;
  width: 27px;
  background-color: #706f6f;
  border-radius: 50px;
  line-height: 15px;
}

.newProjectIcon {
  color: white;
  scale: 1.2;
  padding: 6px;
}

.textWrapper {
  display: block;
  height: 30px;
}

.projectWrapper {
  display: inline-block;
  padding: 0px;
  border-radius: 20px;
}

.videoThumb {
  display: block;
  width: 190px;
  height: 100px;
  object-fit: contain;
  border-radius: 20px;
  cursor: pointer;
}

.account {
  height: 160px;
  padding: 0px 20px;
  width: 100%;
  color: black;
  text-align: center;
}

.tutorials {
  height: 300px;
  width: 97%;
  color: black;
  text-align: center;
  justify-content: center;
}

.middleDiv {
  height: 60px;
  width: 100%;
  padding: 0px 5px;
  background-image: linear-gradient(
    to right,
    red,
    orange,
    yellow,
    green,
    blue,
    indigo,
    violet
  );
  border-radius: 20px;
}

.bottomDiv {
  height: 120px;
  width: 100%;
  margin: 0;
  display: inline-block;
  width: 50%;
  background-image: linear-gradient(
    to right,
    rgba(255, 0, 0, 0),
    rgba(255, 0, 0, 1)
  );
  border-radius: 20px;
}

.tutorialHeader {
  width: 100%;
  display: block;
}

.popupContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
