.mainContainer {
  width: 100%;
  height: 300px;
  border-radius: 25px;
  padding: 5px;
  background-color: #dbd3d3;
  color: rgb(255, 255, 255);
  overflow: scroll;
}

.mainContainer::-webkit-scrollbar {
  display: none;
}

.loadingContainer {
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
}

.listItem {
  display: block;
  padding: 5px 15px;
  border: none;
  list-style-type: none;
  width: 100%;
  text-align: center;
}

.transcript {
  height: 100%;
  padding: 5px;
  display: inline;
}

.singleTextarea {
  width: 80%;
  background-color: rgb(238, 231, 231);
  border: none;
  padding: 5px 10px;
  border-radius: 15px;
  resize: none;
}

.textarea {
  width: 45%;
  background-color: rgb(238, 231, 231);
  border: none;
  padding: 0px 5px;
  border-radius: 15px;
  resize: none;
}

.textarea::-webkit-scrollbar {
  display: none;
}

.translated {
  direction: rtl;
  font-family: "Noto Sans Arabic", sans-serif;
}

.merge {
  display: inline-block;
}
