.mainContainer {
  height: 100%;
}

.inputContainer {
  vertical-align: middle;
}

.inputLabel {
  display: inline-block;
  width: 100%;
  height: 100%;
  cursor: pointer;
  background-color: white;
  color: black;
  padding: 10px;
  text-align: left;
  vertical-align: middle;
  border-radius: 15px;
}

.inputFile {
  display: none;
}

.uploadButton {
  font-family: "Lilita One", sans-serif;
  border: none;
  background-color: black;
  color: orange;
  border-radius: 10px;
  height: 35px;
  width: 100%;
  margin-top: 2px;
}

.uploadButton:hover {
  background-color: rgb(88, 85, 85);
  color: rgb(252, 207, 123);
}
