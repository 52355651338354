.mainContainer {
  display: flex;
  width: 100%;
  padding-bottom: 7px;
  justify-content: center;
}

.transcript {
  display: inline-block;
  padding: 5px;
  width: 70%;
  border: none;
  border-radius: 10px;
  resize: none;
}
