.mainContainer {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.videoFile {
  width: 100%;
  height: 310px;
  object-fit: contain;
  padding: 0px;
}

.loginLink {
  padding-top: 30%;
}
