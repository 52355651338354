.mainContainer {
  padding: 0% 1% 0% 9.4%;
  height: 100%;
}

.subtitleRow {
  margin: 0;
  padding: 5px 0px;
  height: 100px;
}

.originalColumn {
  padding: 0% 2% 0% 1%;
  height: 100%;
}

.translateColumn {
  padding: 0% 1% 0% 2%;
  height: 100%;
}

.timeColumn {
  padding: 1% 1%;
}

.timeRowsContainer {
  height: 50%;
  padding: 15% 20% 25% 0%;
  vertical-align: middle;
}

.timeRows {
  height: 30px;
  padding: 3px 15px 5px 5px;
  vertical-align: middle;
}

.time {
  height: 100%;
  background-color: orange;
  color: white;
  padding: 0;
  border: none;
  text-align: center;
  font-size: 0.7rem;
}

.blackTime {
  background-color: black;
}

.transcript {
  background-color: rgb(238, 231, 231);
  height: 100%;
  resize: none;
  padding: 2%;
  border: none;
}

.transcript::-webkit-scrollbar {
  display: none;
}

.farsiDirection {
  font-family: "Noto Sans Arabic", sans-serif;
  font-weight: 500;
  direction: rtl;
}

.buttonContainer {
  position: relative;
  top: 0px;
  left: 20px;
  border-radius: 20px;
  padding: 0%;
  height: 50px;
  width: 50px;
}

.button {
  color: black;
  background-color: orange;
  border: none;
  border-radius: 25px;
  text-align: center;
  height: 100%;
  width: 100%;
}
