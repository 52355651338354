.mainContainer {
  width: 100;
  height: 70px;
  background-color: orange;
  font-size: xx-large;
  color: white;
  padding: 10px;
  justify-content: space-between;
}

.iconContainer {
  display: inline-block;
  height: 100%;
  width: 8%;
}

.icon {
  height: 100%;
  scale: 0.7;
  display: block;
}

.logoContainer {
  height: 100%;
  width: 90%;
  display: inline-block;
  align-items: center;
}

.logo {
  height: 100%;
  display: block;
  margin: auto;
}
