.mainContainer {
  display: flex;
  padding: 0;
  padding-left: 10px;
  width: 100%;
  justify-content: space-between;
}

.loadingContainer {
  display: inline-block;
}

.loadingStatusHide {
  display: none;
}

.readyText {
  font-size: larger;
  color: blueviolet;
  padding-top: 5%;
}

.exportContainer {
  display: inline-block;
  background-color: #dbd3d3;
  width: 85px;
  border-radius: 10px;
}

.rowContain {
  width: 100%;
  padding: 0;
  margin: 0;
}

.export {
  font-size: small;
  color: white;
  border: none;
  background-color: #dbd3d3;
  border-radius: 10px;
}

.exportHeader {
  background-color: orange;
  height: 30px;
  padding-top: 5px;
}

.mp4Button {
  width: 100%;
  border-radius: 0;
  color: black;
}

.mp4Button:hover {
  background-color: aliceblue;
}

.greenBackground {
  background-color: rgb(29, 197, 29);
}

.srtButton {
  width: 100%;
  border-radius: 0px 0px 10px 10px;
  color: black;
}

.srtButton:hover {
  background-color: aliceblue;
}
